import React, { useState, useEffect } from 'react';
import { createClient } from '@supabase/supabase-js';

const Results = () => {
  // Replace with your Supabase details
  const supabaseUrl = 'https://ufcgnfipctcnvcmpjehw.supabase.co'; // Replace with your Supabase URL
  const supabaseKey =
    'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6InVmY2duZmlwY3RjbnZjbXBqZWh3Iiwicm9sZSI6ImFub24iLCJpYXQiOjE3MDg2MDE5MzUsImV4cCI6MjAyNDE3NzkzNX0.XDQoUK3jbozdUryLskOIO7WWYGhG5EVVeedgFdd59V0'; // Replace with your Supabase key
  const supabase = createClient(supabaseUrl, supabaseKey);

  // State hooks
  const [events, setEvents] = useState([]);
  const [selectedEvent, setSelectedEvent] = useState('');
  const [results, setResults] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    // Fetch events from the "results" table
    const fetchEvents = async () => {
      const { data, error } = await supabase.from('results').select('event');
      if (data) {
        const uniqueEvents = [...new Set(data.map((item) => item.event))];
        setEvents(uniqueEvents);
      } else {
        console.error(error);
      }
    };

    fetchEvents();
  }, []);

  const handleEventChange = (event) => {
    setSelectedEvent(event.target.value);
  };

  const handleFormSubmit = async (event) => {
    event.preventDefault();

    setLoading(true);

    // Fetch results for the selected event
    const { data, error } = await supabase
      .from('results')
      .select('*')
      .eq('event', selectedEvent)
      .single();

    setLoading(false);

    if (data) {
      setResults(data);
    } else {
      console.error(error);
    }
  };

  return (
    <div className="flex bg-gradient-to-r from-slate-900 to-slate-700 flex-col justify-center items-center  h-screen ">
      <div className=' flex  flex-col  items-center justify-center p-4  h-96 font-mono  bg-black rounded-xl bg-clip-padding backdrop-filter backdrop-blur-md bg-opacity-40 border '>
        <form onSubmit={handleFormSubmit} className='flex flex-col justify-center items-center'>
          <label htmlFor="event" className="block text-white text-2xl font-semibold mb-2">
            Select Event:
          </label>
          <select
            id="event"
            name="event"
            value={selectedEvent}
            onChange={handleEventChange}
            className="border p-2 mb-4"
          >
            <option value="">Select an event</option>
            {events.map((event) => (
              <option key={event} value={event}>
                {event}
              </option>
            ))}
          </select>
          <button
            type="submit"
            className="  text-white px-8 border-2 border-red-400 ml-2 py-2 rounded-md hover:bg-gray-600 text-lg"
          >
            Show Results
          </button>
        </form>

        {loading && <p className="text-white mt-4">Loading...</p>}

        {results && !loading && (
          <div className="mt-8">
            <h2 className="text-xl font-semibold mb-2 text-white">Results for {selectedEvent}:</h2>
            <h1 className='text-white text-xl'>1st Place: {results.first}</h1>
            <h1 className='text-white text-xl'>2nd Place: {results.second}</h1>
            <h1 className='text-white text-xl'>3rd Place: {results.third}</h1>
          </div>
        )}
      </div>
    </div>
  );
};

export default Results;
