import React from "react";

function Footer() {
  return (
    <div className=" bg-black flex flex-col w-screen items-center justify-center text-gray-300 p-4 gap-2 tracking-widest">
      <h1>&copy; 2024 All Rights Reserved BY </h1>
      <h1>
        <a href="https://studentsunion.cusat.ac.in">
          Cochin Univeristy Students Union{" "}
        </a>
      </h1>
    </div>
  );
}

export default Footer;
