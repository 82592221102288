import React, { useState, useEffect } from "react";
import { createClient } from "@supabase/supabase-js";
import { Link } from "react-router-dom";

const supabaseUrl = "https://ufcgnfipctcnvcmpjehw.supabase.co";
const supabaseKey =
  "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6InVmY2duZmlwY3RjbnZjbXBqZWh3Iiwicm9sZSI6ImFub24iLCJpYXQiOjE3MDg2MDE5MzUsImV4cCI6MjAyNDE3NzkzNX0.XDQoUK3jbozdUryLskOIO7WWYGhG5EVVeedgFdd59V0"; // Replace with your Supabase key
const supabase = createClient(supabaseUrl, supabaseKey);

const Pointstable = () => {
  const [zoneData, setZoneData] = useState({});
  const [error, setError] = useState(null);

  const fetchData = async () => {
    try {
      const { data, error } = await supabase.from("points").select("*");

      if (error) {
        throw error;
      }

      // Sort data by points in descending order
      const sortedData = data.sort((a, b) => {
        const totalPointsA = Object.values(a).reduce(
          (sum, value) => sum + value,
          0
        );
        const totalPointsB = Object.values(b).reduce(
          (sum, value) => sum + value,
          0
        );

        return totalPointsB - totalPointsA;
      });

      // Calculate zone data from sorted data
      const processedData = {
        zone1: sortedData.reduce((acc, row) => acc + row.zone1, 0),
        zone2: sortedData.reduce((acc, row) => acc + row.zone2, 0),
        zone3: sortedData.reduce((acc, row) => acc + row.zone3, 0),
        zone4: sortedData.reduce((acc, row) => acc + row.zone4, 0),
        zone5: sortedData.reduce((acc, row) => acc + row.zone5, 0),
        zone6: sortedData.reduce((acc, row) => acc + row.zone6, 0),
        zone7: sortedData.reduce((acc, row) => acc + row.zone7, 0),
        zone8: sortedData.reduce((acc, row) => acc + row.zone8, 0),
      };

      setZoneData(processedData);
    } catch (error) {
      setError(error.message);
    }
  };

  useEffect(() => {
    const fetchDataAndSetInterval = async () => {
      await fetchData();

      const intervalId = setInterval(() => {
        fetchData();
      }, 10 * 60 * 1000); // Fetch data every 10 minutes

      return () => clearInterval(intervalId);
    };

    fetchDataAndSetInterval();
  }, []);

  return (
    <div className="flex flex-col pt-5 items-center bg-gradient-to-r from-slate-900 to-slate-700 p-4 w-screen h-screen ">
      <h1 className="text-3xl text-red-400 tracking-widest">POINTS TABLE</h1>
      <div className=" text-white flex flex-col items-center m-4 bg-blue-400  bg-clip-padding backdrop-filter backdrop-blur-md bg-opacity-10 border border-gray-100 w-72 h-screen  gap-5 py-4 justify-around lg:w-96 lg:flex lg:flex-col">
        {Object.entries(zoneData)
          .sort((a, b) => b[1] - a[1]) // Sort zones by points in descending order
          .map(([zoneName, zoneValue], index) => (
            <div
              key={zoneName}
              className={`flex flex-row justify-between bg-black  bg-clip-padding backdrop-filter backdrop-blur-md bg-opacity-40 border-2  border-white w-64 h-8 p-1 px-4 rounded-lg ${
                index === 0 ? "" : "" // Apply special styling to the zone with the highest points
              }`}
            >
              <h1>{`Zone ${zoneName.slice(-1)}`}</h1>
              <h1>{zoneValue}</h1>
            </div>
          ))}
      </div>
      {/* <div className="mt-1">
        <Link to="/resultS">
          <button className="bg-white w-56 h-20 rounded-2xl text-black border-2  hover:bg-red-500 hover:border-red-400 flex justify-around items-center ">
            <div className="bg-red-400 w-5 h-5 rounded-md animate-spin"></div>
            <div className="tracking-widest text-2xl text-gray-700 ">
              RESULTS
            </div>
          </button>
        </Link>
      </div> */}
    </div>
  );
};

export default Pointstable;
