import React from "react";

const Literature = () => {
  return (
    <div className=" flex itim-regular flex-col items-center  p-4 w-screen bg-gradient-to-r from-slate-900 to-slate-700 text-white  ">
      {/* parent */}

      <h1 className="text-6xl p-4">LITERATURE</h1>

      <div className="flex flex-col items-center w-full h-full lg:flex lg:flex-row lg:flex-wrap lg:justify-center lg:items-center gap-4">
        <div className="flex flex-col items-center justify-start  bg-black rounded-xl bg-clip-padding backdrop-filter backdrop-blur-md bg-opacity-40 border border-gray-100 w-52 p-4 shadow-2xl lg:h-64  ">
          <h1 className="font-semibold">ESSAY WRITING</h1>
          <ul>
            <li>MALAYALAM</li>
            <li>ENGLISH</li>
            <li>HINDI</li>
            <li>ARABIC</li>
            <li>TAMIL</li>
            <li>URDU</li>
          </ul>
        </div>

        <div className="flex flex-col items-center justify-start  bg-black rounded-xl bg-clip-padding backdrop-filter backdrop-blur-md bg-opacity-40 border border-gray-100 w-52 p-4 shadow-2xl lg:h-64 ">
          <h1 className="font-semibold text-sm">AKSHARASLOKAM</h1>
          <ul>
            <li>MALAYALAM</li>

            <li>ARABIC</li>

            <li>SANSKRIT</li>
          </ul>
        </div>

        <div className="flex flex-col items-center justify-start  bg-black rounded-xl bg-clip-padding backdrop-filter backdrop-blur-md bg-opacity-40 border border-gray-100 w-52 p-4 shadow-2xl lg:h-64 ">
          <h1 className="font-semibold">RECITATION</h1>
          <ul>
            <li>MALAYALAM</li>
            <li>ENGLISH</li>
            <li>HINDI</li>
            <li>ARABIC</li>
            <li>TAMIL</li>
            <li>SANSKRIT</li>
            <li>URDU</li>
          </ul>
        </div>

        <div className="flex flex-col items-center justify-start  bg-black rounded-xl bg-clip-padding backdrop-filter backdrop-blur-md bg-opacity-40 border border-gray-100 w-52 p-4 shadow-2xl lg:h-64 ">
          <h1 className="font-semibold">ELOCUTION</h1>
          <ul>
            <li>MALAYALAM</li>
            <li>ENGLISH</li>
            <li>HINDI</li>
            <li>ARABIC</li>
            <li>TAMIL</li>
            <li>SANSKRIT</li>
          </ul>
        </div>

        <div className="flex itim-regular itim-regular flex-col items-center justify-start  bg-black rounded-xl bg-clip-padding backdrop-filter backdrop-blur-md bg-opacity-40 border border-gray-100 w-52 p-4 shadow-2xl lg:h-64 ">
          <h1 className="font-semibold">SHORT STORY WRITING</h1>
          <ul>
            <li>MALAYALAM</li>
            <li>ENGLISH</li>
            <li>HINDI</li>
            <li>ARABIC</li>
            <li>TAMIL</li>
            <li>SANSKRIT</li>
            <li>URDU</li>
          </ul>
        </div>

        <div className="flex flex-col items-center justify-start  bg-black rounded-xl bg-clip-padding backdrop-filter backdrop-blur-md bg-opacity-40 border border-gray-100 w-52 p-4 shadow-2xl lg:h-64 ">
          <h1 className="font-semibold">DEBATE</h1>
          <ul>
            <li>MALAYALAM</li>
            <li>ENGLISH</li>
            <li>HINDI</li>
          </ul>
        </div>

        <div className="flex flex-col items-center justify-start  bg-black rounded-xl bg-clip-padding backdrop-filter backdrop-blur-md bg-opacity-40 border border-gray-100 w-52 p-4 shadow-2xl lg:h-64 ">
          <h1 className="font-semibold">POETRY WRITING</h1>
          <ul>
            <li>MALAYALAM</li>
            <li>ENGLISH</li>
            <li>HINDI</li>
            <li>ARABIC</li>
            <li>TAMIL</li>
            <li>SANSKRIT</li>
            <li>URDU</li>
          </ul>
        </div>

        <div className="flex flex-col  justify-center  bg-black rounded-xl bg-clip-padding backdrop-filter backdrop-blur-md bg-opacity-40 border border-gray-100 w-52 p-4 shadow-2xl lg:h-64 ">
          <h1 className="font-semibold">SHORT FILM REVIEW</h1>
          <h1 className="font-semibold">QUIZ</h1>
        </div>
      </div>
    </div>
  );
};

export default Literature;
