// Gallery.js
import React, { useEffect, useState } from 'react';
import { createClient } from '@supabase/supabase-js';

const supabaseUrl = 'https://ufcgnfipctcnvcmpjehw.supabase.co'; // Replace with your Supabase URL
const supabaseKey =   "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6InVmY2duZmlwY3RjbnZjbXBqZWh3Iiwicm9sZSI6ImFub24iLCJpYXQiOjE3MDg2MDE5MzUsImV4cCI6MjAyNDE3NzkzNX0.XDQoUK3jbozdUryLskOIO7WWYGhG5EVVeedgFdd59V0"; // Replace with your Supabase key

const Gallery = () => {
  const [images, setImages] = useState([]);
  const supabase = createClient(supabaseUrl, supabaseKey);

  useEffect(() => {
    const fetchImages = async () => {
      try {
        const { data, error } = await supabase
          .from('images') // Replace with your table name
          .select('url'); // Replace with your image URL column name

        if (error) {
          console.error('Error fetching images:', error.message);
        } else {
          setImages(data);
        }
      } catch (error) {
        console.error('Error fetching images:', error.message);
      }
    };

    fetchImages();
  }, [supabase]);

  return (
    <div className="container mx-auto p-8">
      <h1 className="text-3xl font-bold mb-6">Image Gallery</h1>
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
        {images.map((image, index) => (
          <img
            key={image.id} // Use a unique identifier as the key
            src={image.url} // Replace with your image URL column name
            alt={`Image ${index + 1}`}
            className="w-full h-64 object-cover rounded-md cursor-pointer"
            loading="lazy"
          />
        ))}
      </div>
    </div>
  );
};

export default Gallery;