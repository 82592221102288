import React from "react";
import bg from "../images/bg3.png";
import { Link } from "react-router-dom";
import sam from "../images/sam4.png";
function About() {
  return (
    <div
      id="aboutsec"
      className="  py-20 bg-gradient-to-r from-slate-900 to-slate-700 flex flex-col justify-center items-center lg:flex lg:flex-row lg:justify-around lg:items-center w-screen  "
    >
      <div
        className=" mb-24  absolute w-screen h-screen bg-cover z-0 lg:mt-72"
        style={{ backgroundImage: `url(https://ufcgnfipctcnvcmpjehw.supabase.co/storage/v1/object/public/home/bg3.png?t=2024-03-03T06%3A59%3A04.097Z)` }}
      >
        <div className="absolute bg-black opacity-70 z-20 w-full h-full"></div>
      </div>

      {/* <div className="mt-4 lg:w-96 lg:h-96 lg:rounded-full lg:bg-black hover:bg-red-800 z-10">
        <img src={about} className="w-72 h-72 rounded-full lg:p-1 lg:w-96 lg:h-96"></img>
      </div> */}
      <style>
        @import
        url('https://fonts.googleapis.com/css2?family=Itim&display=swap');
      </style>
      <div className=" flex flex-col items-center justify-center itim-regular text-center text-red-400 z-10 lg:w-1/2 ">
        <h1 className="text-2xl tracking-widest mt-3 text-white  lg:m-2">
          ABOUT
        </h1>
        <p className="text-gray-300 p-3  lg:text-left">
          The Cochin University Students Union's arts festival is pleased to
          announce the return of SARGAM for another year. The unique arts
          festival is centred around a lively group of more than 9000 students
          from CUSAT campuses (Thrikakkara, Lakeside, Pullincunnu) and other
          associated colleges. The festivities for the five-day festival begin
          on February 29 and last through March 4. Make sure to put February
          29–March 4 on your calendars so you don't miss the fun! Will see you
          there, prepare to be amazed!
        </p>
        <div className="mt-2 lg:hidden">
          <Link to="/gallery">
            <button className=" text-red-400 w-56 h-20 rounded-2xl border-2  hover:bg-black hover:bg-opacity-70 hover:text-white hover:border-red-400 flex justify-around items-center ">
              <div className="flex  items-center justify-between  rounded-md ">
                <img src={sam} className=" w-8 h-8 rounded-2xl animate-spin " />
              </div>
              <div className="tracking-widest text-2xl  ">GLIMPSES</div>
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default About;
