import React from "react";
import Gallery from "../components/Gallery";
const Photos = () => {
  return (
    <div className="bg-black">
      <Gallery />
    </div>
  );
};

export default Photos;
