import React, { useState, useEffect } from "react";
// ... other imports
import heroim from "../images/Sargamwhiteplain.png";
import bgvd from "../images/bgvd2.mp4";
import SargamText from "./SargamText";
import { Link } from "react-router-dom";

const Hero = () => {
  return (
    <div className="flex flex-col w-screen h-screen lg:flex lg:flex-col lg:justify-center lg:items-center">
      {/* Background Video */}
      <div className="absolute inset-0 w-screen h-screen object-cover z-0">
        <video autoPlay loop muted className="w-screen h-screen object-cover">
          <source src={bgvd}type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        {/* Black Overlay */}
        <div className="absolute inset-0 bg-black opacity-80"></div>
      </div>

      <div className="flex flex-row justify-center items-center lg:">
        {/* left of pc */}
        <div className=" flex flex-col items-center z-10 gap-4   ">
          <div className="flex flex-col ">
            <style>
              @import
              url('https://fonts.googleapis.com/css2?family=Anta&display=swap')
            </style>
            <h1 className="text-[#FF1818] text-6xl  animate-pulse-fade font-semibold lg:text-9xl anta-regular">
              SARGAM
            </h1>
            <h1 className=" text-white text-6xl animate-bounce pt-2 lg:text-9xl anta-regular">
              24
            </h1>
            <h2 className="text-gray-500 text-lg lg:text-2xl ">
              Cochin University Arts Fest
            </h2>
          </div>

          <div className="flex items-center justify-center lg:hidden">
            <img src={heroim} alt="" className="h-72" />
          </div>

          {/* buttons section */}
          <div className=" flex flex-col items-center lg:flex lg:flex-col lg:items-start lg:px-6">
            <div className=" flex flex-col justify-center items-center tracking-widest border-2 border-red-400 rounded-2xl h-16 w-48 lg:w-56 lg:h-20">
              <h1 className="text-white">February 29</h1>
              <h1 className="text-white">March 1,2,3,4</h1>
            </div>

            <div className=" lg:hidden">
              <SargamText />
            </div>

            <div className="mt-1">
              <Link to="/livescore">
                <button className="bg-white w-56 h-20 rounded-2xl text-black border-2  hover:bg-red-500 hover:border-red-400 flex justify-around items-center ">
                  <div className="bg-red-400 w-5 h-5 rounded-md animate-spin"></div>
                  <div className="tracking-widest text-2xl text-gray-700 ">
                    LIVE SCORE
                  </div>
                </button>
              </Link>
            </div>
          </div>

          <div className=" hidden lg:flex lg:pl-6">
            <SargamText />
          </div>

          <div className="mt-1 lg:hidden felx flex-col justify-between">
              <Link to="/results">
                <button className="bg-white w-56 h-20 rounded-2xl text-black border-2  hover:bg-red-500 hover:border-red-400 flex justify-around items-center ">
                  <div className=""><svg className="size-12 animate-pulse" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="gold" class="w-6 h-6">
  <path stroke-linecap="round" stroke-linejoin="round" d="M16.5 18.75h-9m9 0a3 3 0 0 1 3 3h-15a3 3 0 0 1 3-3m9 0v-3.375c0-.621-.503-1.125-1.125-1.125h-.871M7.5 18.75v-3.375c0-.621.504-1.125 1.125-1.125h.872m5.007 0H9.497m5.007 0a7.454 7.454 0 0 1-.982-3.172M9.497 14.25a7.454 7.454 0 0 0 .981-3.172M5.25 4.236c-.982.143-1.954.317-2.916.52A6.003 6.003 0 0 0 7.73 9.728M5.25 4.236V4.5c0 2.108.966 3.99 2.48 5.228M5.25 4.236V2.721C7.456 2.41 9.71 2.25 12 2.25c2.291 0 4.545.16 6.75.47v1.516M7.73 9.728a6.726 6.726 0 0 0 2.748 1.35m8.272-6.842V4.5c0 2.108-.966 3.99-2.48 5.228m2.48-5.492a46.32 46.32 0 0 1 2.916.52 6.003 6.003 0 0 1-5.395 4.972m0 0a6.726 6.726 0 0 1-2.749 1.35m0 0a6.772 6.772 0 0 1-3.044 0" />
</svg>
</div>
                  <div className="tracking-widest text-2xl text-gray-700 ">
                    Results
                  </div>
                </button>
              </Link>
            </div>
          
        </div>

        {/* right of pc */}
        <div className=" hidden lg:z-10 lg:flex lg:justify-center lg:items-center">
          <img src={heroim} alt="" className="h-[45rem] w-[45rem]" />
        </div>
      </div>
    </div>
  );
};

export default Hero;
